// xs, sm, md, lg, xl
.shark-cookie {
    display: none;
    input:hover {
        cursor: pointer;
    }
}
// sm, md, lg, xl
@media (min-width: 576px) {
}

// md, lg, xl 
@media (min-width: 768px) {
}

// lg, xl
@media (min-width: 992px) {
}

// xl
@media (min-width: 1200px) {
    .shark-cookie {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 1010;
        padding: 15px 0;
        background: white;
        border-top: 1px solid #1d2632;
    }
}

